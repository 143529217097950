import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import pluralize from "pluralize";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import faFollow from "styles/icons/faFollow";

const baseStyles = {
  container: {
    display: "flex",
    gap: 5,
    marginTop: -5,
  },
  bubble: {
    ...gStyles.avalonBold,
    fontSize: ".813rem",
    lineHeight: "16px",

    display: "flex",
    gap: "0.25rem",
    alignItems: "center",

    color: colours.darkGrey,
    background: colours.neutral,

    padding: "0 8px",

    margin: 0,

    borderRadius: 100,

    width: "fit-content",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",

    ":hover": {
      cursor: "pointer",
    },
  },
  icon: {
    fontSize: "0.75rem",
    margintop: 2,
  },
};

export default function CreatorCredits(props) {
  const { styles } = useStyles(baseStyles, props);

  const { creator } = props;

  const epCreditCount = creator?.get("episode_credit_count");
  const followersCount = creator?.get("follower_count");

  return (
    <div className={css(styles.container)}>
      <BasicTooltip renderTooltip={() => `${epCreditCount} Episode Appearance`}>
        {(tooltipProps) => (
          <div {...tooltipProps} className={css(styles.bubble)}>
            {epCreditCount} {pluralize("credit", epCreditCount)}
          </div>
        )}
      </BasicTooltip>
      <BasicTooltip renderTooltip={() => `${followersCount} Followers`}>
        {(tooltipProps) => (
          <div {...tooltipProps} className={css(styles.bubble)}>
            <span className={css(styles.icon)}>
              <FontAwesomeIcon icon={faFollow} dataid="like" />
            </span>
            {followersCount}
          </div>
        )}
      </BasicTooltip>
    </div>
  );
}
