import { Link } from "react-router-dom";

import EntityLink from "../../EntityLink";
import { getGroupedRoles } from "./utils";

import getCreatorUrl from "utils/entity/getCreatorUrl";
import { truncateString } from "utils/truncate";

import useIsModerator from "hooks/useIsModerator";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  container: {
    marginBottom: "0.5rem",
  },
  hosts: {
    display: "flex",
    alignItems: "Center",
    gap: "0.3rem",
    width: "100%",
    overflow: "hidden",
    marginBottom: 5,
  },
  text: {
    ...gStyles.avalonBold,
    fontSize: ".813rem",
    lineHeight: "16px",
    display: "block",
    color: "var(--color-neutral-d5)",
    margin: 0,
    width: "max-content",
    whiteSpace: "noWrap",

    cursor: "pointer",

    ":hover": {
      color: colours.primary,
    },
    ":last-child": {
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  label: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    lineHeight: "16px",
    display: "block",
    color: "var(--color-neutral-d5)",
    margin: 0,
    width: "max-content",
    whiteSpace: "noWrap",
  },
};

function CreatorCardHosts(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { creator } = props;

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const { isModerator } = useIsModerator();
  const creatorUrl = getCreatorUrl(creator);

  const maxItems = mobile ? 1 : 2;

  const groupedRoles = getGroupedRoles({ creator, isModerator });

  if (!groupedRoles) {
    return null;
  }

  if (groupedRoles?.guest && Object.keys(groupedRoles)?.length > 1) {
    delete groupedRoles.guest;
  }

  return (
    <section className={css(styles.hosts)}>
      {Object.values(groupedRoles)?.map((role, index) => {
        if (!role) {
          return null;
        }

        if (index === 0) {
          const roles = role?.roles[0];
          const items = role?.items;
          const itemsToRender = items.slice(0, maxItems);
          const roleLabel = roles?.get("title");
          const amountLeft = items?.length - maxItems;

          return (
            <div className={css(styles.hosts)} key={roles?.get("code")}>
              <h4 className={css(styles.label)}>{`${roleLabel} on`} </h4>
              {itemsToRender?.map((item, index) => {
                const title = creator?.get("title");

                return item.get("entity") ? (
                  <EntityLink
                    key={index}
                    className={css(styles.entityTitle)}
                    entity={item.get("entity")}
                    entity_type={item.get("entity_type")}
                    entity_id={item.getIn(["entity", "id"])}
                    inline
                  >
                    <h4 className={css(styles.text)}>
                      {`${truncateString(item.getIn(["entity", "title"]), 30)}${
                        index !== itemsToRender?.length - 1 || amountLeft > 0
                          ? ","
                          : ""
                      }`}
                    </h4>
                  </EntityLink>
                ) : (
                  <span key={index}>{title}</span>
                );
              })}

              <h4 className={css(styles.text)}>
                {amountLeft > 0 && (
                  <Link
                    className={css(styles.show)}
                    to={`${creatorUrl}/appearances`}
                    data-testid="viewMore"
                  >
                    + {amountLeft} more.
                  </Link>
                )}
              </h4>
            </div>
          );
        }
        return null;
      })}
    </section>
  );
}

export default CreatorCardHosts;
