/* eslint-disable react/display-name */
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { followEntityPromise, unfollowEntityPromise } from "routines/follow";

import EllipsisMenuBackItem from "components/Buttons/EllipsisMenuButton/EllipsisMenuBackItem";
import EllipsisMenuButton from "components/Buttons/EllipsisMenuButton/EllipsisMenuButton";
import EllipsisMenuItem from "components/Buttons/EllipsisMenuButton/EllipsisMenuItem";

import CreatorSharingLinks from "../../../Creator/CreatorSharingLinks";

import authActions from "actions/auth";
import modalActions from "actions/modals";
import { selectAuthLoggedIn } from "selectors/auth";
import getCreatorEditUrl from "utils/entity/getCreatorEditUrl";
import getCreatorName from "utils/entity/getCreatorName";
import sendGAEvent from "utils/sendGAEvent";
import { getCreatorUrl } from "utils/url/creatorUrls";

import useActionCreators from "hooks/useActionCreators";
import useActivityContext from "hooks/useActivityContext";
import useReduxState from "hooks/useReduxState";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";

const baseStyles = {
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
};

const ELLIPSIS_STEPS = ["default", "share", "external"];

const CreatorEllipsisMenu = (props) => {
  const { creator, showEllipsisItems } = props;
  const { styles } = useStyles(baseStyles, props);
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const { startAuthFlow } = useActionCreators(authActions);
  const isLoggedIn = useReduxState((state) => selectAuthLoggedIn(state), []);

  const suggestEditProps = useMemo(() => {
    const suggestEditTo = getCreatorEditUrl(creator);

    return isLoggedIn
      ? { to: suggestEditTo, link: true }
      : {
          onClick: () =>
            startAuthFlow(undefined, {
              entity: creator,
              entity_type: "creator",
              entityTitle: `suggest an edit for "${creator.get("name")}"`,
              entityAction: "action",
              analyticsVariables: {
                triggered_by: "suggestEditButton",
              },
              forward: suggestEditTo,
            }),
        };
  }, [creator, startAuthFlow, isLoggedIn]);

  const activityContext = useActivityContext();

  const items = useMemo(
    () => showEllipsisItems || ["view", "follow", "share", "suggest", "claim"],
    [showEllipsisItems]
  );

  const analyticsVariables = useMemo(
    () => ({
      ellipsisMenuType: "CreatorEllipsisMenu",
      creator_id: creator && creator.get("id"),
    }),
    [creator]
  );

  const preventDefault = (e) => {
    e && e.stopPropagation();
    e && e.preventDefault();
  };

  const handleShareClick = useCallback(
    (onChangeStep) => (e) => {
      sendGAEvent({
        creator_id: creator?.get("id"),
        creator_name: creator?.get("name"),
        entity_type: "creator",
        context: "ellipsis menu",
        componentContext: "CreatorEllipsisMenu",
        ...activityContext,
        action: "shareModalOpen",
      });
      preventDefault(e);
      setSubMenuOpen(!subMenuOpen);
      onChangeStep && onChangeStep("share")(e);
    },
    [activityContext, creator, subMenuOpen]
  );

  const currentUserFollows = creator?.getIn(["user_data", "follows"]);

  const { followEntity, unfollowEntity } = useRoutinePromises({
    followEntity: followEntityPromise,
    unfollowEntity: unfollowEntityPromise,
  });

  const handleToggleFollowClick = useCallback(() => {
    if (currentUserFollows) {
      unfollowEntity({
        entity_type: "creator",
        entity_id: creator.get("id"),
        entity: creator,
      });
    } else {
      followEntity({
        entity_type: "creator",
        entity_id: creator.get("id"),
        entity: creator,
      });
    }
  }, [creator, currentUserFollows, followEntity, unfollowEntity]);

  const handleClaimClick = useCallback(
    (contentProps) => (e) => {
      if (contentProps && contentProps.closeMenu) {
        contentProps.closeMenu(e);
      }

      if (contentProps && contentProps.closeEllipsisPopover) {
        contentProps.closeEllipsisPopover(e);
      }

      showModal("verifyCreator", { creator });

      sendGAEvent({
        action: "claimCreatorModal",
        entity_type: "creator",
        entity_id: creator.get("id"),
        creator_name: getCreatorName(creator),
      });
    },
    [creator, showModal]
  );

  const renderFollowLabel = useCallback(() => {
    if (currentUserFollows) {
      return "Unfollow";
    }
    return "Follow";
  }, [currentUserFollows]);

  const renderItem = useCallback(
    (contentProps) => (type) => {
      const { onChangeStep } = contentProps;

      switch (type) {
        case "follow":
          return (
            <EllipsisMenuItem
              key="follow"
              entity={creator}
              entity_type="creator"
              label={renderFollowLabel()}
              onClick={handleToggleFollowClick}
              onMouseDown={preventDefault}
              onMouseUp={preventDefault}
            />
          );
        case "share":
          return (
            <EllipsisMenuItem
              key="share"
              entity={creator}
              entity_type="creator"
              label="Share"
              onClick={handleShareClick(onChangeStep)}
              onMouseDown={preventDefault}
              onMouseUp={preventDefault}
            />
          );
        case "view":
          return (
            <EllipsisMenuItem
              key="view-creator"
              entity={creator}
              entity_type="creator"
              label="View Creator"
              to={getCreatorUrl(creator)}
              link
            />
          );
        case "suggest":
          return (
            <EllipsisMenuItem
              key="suggest-creator"
              entity={creator}
              entity_type="suggest"
              label="Suggest an Edit"
              {...suggestEditProps}
            />
          );
        case "claim":
          return (
            <EllipsisMenuItem
              key="claim-creator"
              entity={creator}
              entity_type="claim"
              label="Claim Profile"
              onClick={handleClaimClick(contentProps)}
            />
          );

        default:
          return null;
      }
    },
    [
      creator,
      renderFollowLabel,
      handleToggleFollowClick,
      handleShareClick,
      suggestEditProps,
      handleClaimClick,
    ]
  );

  const renderContent = useCallback(
    (contentProps) => {
      const { currentStep, onBack } = contentProps;

      switch (currentStep) {
        case "share":
          return (
            <div key="share" className={css(styles.column)}>
              <EllipsisMenuBackItem {...contentProps} onBack={onBack} />
              <CreatorSharingLinks
                creator={creator}
                downshiftProps={contentProps}
              />
            </div>
          );
        default:
          return (
            <div key="default" className={css(styles.column)}>
              {items.map(renderItem(contentProps))}
            </div>
          );
      }
    },
    [styles.column, creator, items, renderItem]
  );

  return (
    <EllipsisMenuButton
      renderContent={renderContent}
      steps={ELLIPSIS_STEPS}
      analyticsVariables={analyticsVariables}
      subMenuOpen={subMenuOpen}
      setSubMenuOpen={setSubMenuOpen}
      passedEllipsisButtonStyles={props?.styles}
    />
  );
};

CreatorEllipsisMenu.propTypes = {
  creator: PropTypes.instanceOf(Map).isRequired,
  showEllipsisItems: PropTypes.array,
};

CreatorEllipsisMenu.defaultProps = {
  showEllipsisItems: null,
};

export default React.memo(CreatorEllipsisMenu);
