import { faPencil } from "@fortawesome/pro-regular-svg-icons/faPencil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import getCreatorEditUrl from "utils/entity/getCreatorEditUrl";
import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

const baseStyles = {
  button: {
    height: "2rem",
    width: "2rem",
    display: "grid",
    placeItems: "center",
    border: `1px solid ${colours.borderGrey}`,
    borderRadius: "25px",
    fontSize: "0.813rem",

    ":hover": {
      cursor: "pointer",
      backgroundColor: "#f7f7f7",
    },
  },
};

function CreatorClaimedAction(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { creator } = props;

  const creatorUrl = getCreatorEditUrl(creator);

  const claimed = creator?.getIn(["user_data", "claimed"]);

  const handleClick = useCallback(
    () =>
      sendGAEvent({
        action: "editCreatorButtonClicked",
        creatorName: creator?.get("name"),
        creatorId: creator?.get("id"),
      }),
    [creator]
  );

  if (!claimed) {
    return null;
  }

  return (
    <BasicTooltip renderTooltip={() => "Edit this creator"}>
      {(tooltipProps) => (
        <Link
          {...tooltipProps}
          to={creatorUrl}
          className={css(styles.button)}
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={faPencil} dataid="edit" />
        </Link>
      )}
    </BasicTooltip>
  );
}

export default CreatorClaimedAction;
