import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { Link } from "react-router-dom";

import CreatorProfileHoverPopover from "../../../Profiles/CreatorProfileHoverPopoverAsync";

import cachedImage from "utils/entity/cachedImage";
import getCreatorUrl from "utils/entity/getCreatorUrl";
import getEntityImageTitle, {
  getEntityImageAlt,
} from "utils/entity/getEntityImageTitle";
import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  image: {
    height: "5.188rem",
    width: "5.188rem",
    borderRadius: "50%",
  },
};

function CreatorCardImage(props) {
  const { styles, css } = useStyles([baseStyles], props);
  const { creator } = props;

  const handleClick = useCallback(() => {
    sendGAEvent({
      action: "creatorCardImageClicked",
      creatorId: creator?.get("id"),
      creator_name: creator?.get("name"),
      context: "Creator Card",
    });
  }, [creator]);

  const MAIN_SIZE_IMAGE = 64;
  const src = creator.get("profile_image_url");
  const alt = getEntityImageAlt(creator, "creator");
  const title = getEntityImageTitle(creator, "creator");
  const creatorHeaderImageUrl = cachedImage(src, MAIN_SIZE_IMAGE, null, {
    user: true,
  });
  const creatorHeaderImageUrlRetina = cachedImage(
    src,
    MAIN_SIZE_IMAGE * 2,
    null,
    { user: true }
  );

  return (
    <Link to={getCreatorUrl(creator)} onClick={handleClick}>
      <CreatorProfileHoverPopover creator={creator} inline>
        <img
          src={creatorHeaderImageUrl}
          srcSet={`${creatorHeaderImageUrl} 1x, ${creatorHeaderImageUrlRetina} 2x`}
          className={css(styles.image)}
          alt={alt}
          title={title}
        />
      </CreatorProfileHoverPopover>
    </Link>
  );
}

CreatorCardImage.propTypes = {
  creator: PropTypes.instanceOf(Map),
};

CreatorCardImage.defaultProps = {
  creator: null,
};

export default CreatorCardImage;
