import { getRole } from "utils/roles";

export const getGroupedRoles = ({ creator, isModerator }) => {
  const roleSummaryKey = `role_summary${isModerator ? "_unconfirmed" : ""}`;
  const summary = creator?.get(roleSummaryKey);

  if (summary && summary.size > 0) {
    return summary
      .filter((item) => item.has("entity"))
      .reduce((agg, item) => {
        const rolesKey = item
          .get("roles")
          .filter((elem) => !!elem)
          .sort((a, b) => a.get("code").localeCompare(b.get("code")))
          .map((r) => r.get("code"))
          .filter((value, index, self) => {
            const isEqual = self.indexOf(value) === index;
            const foundRole = getRole(value);

            return isEqual && !!foundRole;
          })
          .join("-");

        if (rolesKey) {
          agg[rolesKey] = {
            items: [...(agg[rolesKey] ? agg[rolesKey].items : []), item],
            roles: item
              .get("roles")
              .toArray()
              .filter(
                (value, index, self) =>
                  self.indexOf(
                    self.find((v) => v.get("code") === value.get("code"))
                  ) === index
              ),
          };
        }

        return agg;
      }, {});
  }

  return null;
};
