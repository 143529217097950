import { Map } from "immutable";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import EntitySharingLinks from "components/Sharing/EntitySharingLinks";

import { getBaseUrl } from "constants/base";
import { getCreatorUrl } from "utils/url/creatorUrls";

const CreatorSharingLinks = (props) => {
  const { creator, eventProps: passedEventProps } = props;

  const { info, eventProps } = useMemo(
    () => ({
      info: {
        url: `${getBaseUrl()}${getCreatorUrl(creator)}`,
        title: `${creator.get("name")} on Podchaser`,
        image_url: `${creator.get("image_url")}`,
      },
      eventProps: {
        creator_id: creator.get("id"),
        ...passedEventProps,
      },
    }),
    [passedEventProps, creator]
  );

  return (
    <EntitySharingLinks
      {...props}
      entity={creator}
      entity_type="creator"
      info={info}
      eventProps={eventProps}
    />
  );
};

CreatorSharingLinks.propTypes = {
  creator: PropTypes.instanceOf(Map),
  eventProps: PropTypes.object,
};
CreatorSharingLinks.defaultProps = {
  creator: Map({}),
  eventProps: {},
};

export default React.memo(CreatorSharingLinks);
