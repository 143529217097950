import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Map } from "immutable";
import React from "react";
import { Link } from "react-router-dom";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import PodcastImage from "../PodcastImage";

import getCreatorUrl from "utils/entity/getCreatorUrl";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  appearances: {
    display: "flex",
    alignItems: "Center",
    gap: "0.3rem",
  },
  button: {
    height: "1.5rem",
    width: "1.5rem",
    display: "grid",
    placeItems: "center",
    border: `1px solid ${colours.borderGrey}`,
    borderRadius: "0.25rem",
    fontSize: "0.813rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  bottomRowContainer: {
    display: "grid",
    gridTemplateColumns: "50px 1fr",
    gridGap: "1rem",
    alignItems: "center",
    maxWidth: "100%",
  },
  bottomRowLabel: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    margin: 0,
    display: "flex",
    gap: "0.5rem",
  },
};

function CreatorAppearances(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { creator } = props;

  const creatorUrl = getCreatorUrl(creator);

  const appearances = creator?.get("role_summary")?.slice(0, 6);

  if (!appearances || appearances?.size === 0) {
    return null;
  }

  return (
    <div className={css(styles.bottomRowContainer, styles.largeOnly)}>
      <h3 className={css(styles.bottomRowLabel)}>Credits</h3>
      <section className={css(styles.appearances)}>
        {appearances?.map((role) => {
          const podcast = role?.get("entity");

          if (podcast && Map.isMap(podcast) && podcast?.get("id")) {
            return <PodcastImage key={podcast?.get("id")} podcast={podcast} />;
          }

          return null;
        })}
        <BasicTooltip renderTooltip={() => "View More"}>
          {(tooltipProps) => (
            <Link
              {...tooltipProps}
              target="_blank"
              to={`${creatorUrl}/appearances`}
              className={css(styles.button)}
              data-testid="viewMore"
            >
              <FontAwesomeIcon icon={faChevronRight} dataid="viewMore" />
            </Link>
          )}
        </BasicTooltip>
      </section>
    </div>
  );
}

export default CreatorAppearances;
