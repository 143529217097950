import { Map } from "immutable";
import PropTypes from "prop-types";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import CardFollowAction from "components/Common/table/commons/CardFollowAction";
import ShowMoreContainerWithHighlight from "components/Common/table/commons/ShowMoreContainerWithHighlight";
import HighLightText from "components/Highlight/HighLightText";

import NewCardTitle from "../NewCardTitle";
import CreatorAppearances from "./CreatorAppearances";
import CreatorCardHosts from "./CreatorCardHosts";
import CreatorCardImage from "./CreatorCardImage";
import CreatorClaimedAction from "./CreatorClaimedAction";
import CreatorCredits from "./CreatorCredits";
import CreatorEllipsisMenu from "./CreatorEllipsisMenu";

import { DESKTOP_BOTTOM_ROW_HEIGHT } from "constants/podcast";
import getCreatorUrl from "utils/entity/getCreatorUrl";

import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";
import colours from "styles/colours";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  creatorDesktopCardNoPanel: {
    ...cardStyles.cardNoPanel,
  },
  bottomRow: {
    ...cardStyles.bottomRow,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "1rem",
    alignItems: "center",

    [createBreakpoint({ min: 1115 })]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  creatorDesktopCard: {
    ...cardStyles.mobileCard,
    minHeight: "13rem",
    padding: "1.5rem 1rem",
    border: `1px solid var(--color-neutral-d85)`,
    borderRadius: "0.75rem",

    [ScreenSizes.mdAndAbove]: {
      ...cardStyles.desktopCard,
      padding: "1.5rem",
      maxWidth: "54rem",
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: 8,
    },
  },
  middleRow: {
    ...cardStyles.topRow,
    borderBottom: "none",
    display: "flex",
    flexDirection: "column",
    margin: "1rem 0",
    paddingBottom: 0,

    [ScreenSizes.lgAndAbove]: {
      borderBottom: "none",
    },
  },
  topFirstRow: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr max-content",
    gap: "1rem",
    marginBottom: "1rem",
  },
  topFirstRowCol1: {
    display: "grid",
    gridTemplateRows: "repeat(2, max-content)",
    gap: "1rem",
  },
  topActions: {
    display: "none",

    [createBreakpoint({ min: 1115 })]: {
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
      height: "2rem",
      width: "100%",
    },
  },
  bottomActions: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    height: "max-content",
    width: "100%",
    marginTop: "1rem",
    paddingTop: "1.5rem",
    borderTop: `1px solid ${colours.borderGrey}`,

    [createBreakpoint({ min: 1115 })]: {
      display: "none",
    },
  },
};

const insidePanelStyles = {
  creatorDesktopCard: {
    ...cardStyles.noRaisedCard,
    ...cardStyles.flatCard,
  },
  bottomRow: {
    ...cardStyles.bottomRowInsidePanel,
  },
};

const ellipsisStyles = {
  actionButtonsContainer: {
    borderRadius: "25px",
  },
  ellipsisButtonInner: {
    fontSize: "0.75rem",
    height: "2rem",
    width: "2rem",
    borderRadius: "25px",
    border: `1px solid ${colours.borderGrey}`,
  },
};

const followStyle = {
  follow: {
    color: colours.black,
    background: colours.white,
    border: `1px solid ${colours.borderGrey}`,
    marginRight: "auto",

    [createBreakpoint({ min: 1115 })]: {
      marginRight: 0,
    },
  },
  following: {
    border: "none",
  },
};

const CreatorDesktopCard = (props) => {
  const {
    creator,
    noPanel,
    insidePanel,
    renderOnMount,

    principalSearchTerm,
    secondarySearchTerm,
  } = props;

  const { styles, css } = useStyles(
    [baseStyles, insidePanel && insidePanelStyles],
    props
  );

  if (!creator) {
    return null;
  }

  const renderTopRow = () => (
    <div className={css(styles.topFirstRow)}>
      <CreatorCardImage creator={creator} />
      <div className={css(styles.topFirstRowCol1)}>
        <NewCardTitle
          entity={creator}
          entityType="creator"
          badgeSize={13}
          renderOnMount={renderOnMount}
          title={
            <HighLightText
              textToHighlight={creator?.get("name")}
              principalSearchTerm={principalSearchTerm}
              secondarySearchTerm={secondarySearchTerm}
            />
          }
          entityUrl={getCreatorUrl(creator)}
        />
        <CreatorCredits creator={creator} />
      </div>
      <div className={css(styles.topActions)}>
        <CreatorClaimedAction creator={creator} />
        <CardFollowAction
          entity={creator}
          entity_type="creator"
          allowFollow
          styles={followStyle}
        />
        <CreatorEllipsisMenu
          creator={creator}
          id="ellipsis"
          styles={ellipsisStyles}
          closeOnOutsideclick
        />
      </div>
    </div>
  );
  const renderMiddleRow = () => (
    <div className={css(styles.middleRow)}>
      <CreatorCardHosts creator={creator} />

      <ShowMoreContainerWithHighlight
        entity={creator}
        entityType="creator"
        description={creator.get("bio") || creator.get("subtitle_long")}
        principalSearchTerm={principalSearchTerm}
        secondarySearchTerm={secondarySearchTerm}
      />
    </div>
  );

  const renderBottomRow = () => (
    <LazyLoadComponent
      renderOnMount={renderOnMount}
      height={DESKTOP_BOTTOM_ROW_HEIGHT}
    >
      <div
        className={css(
          styles.bottomRow,
          insidePanel && styles.bottomRowInsidePanel
        )}
      >
        <CreatorAppearances creator={creator} />
      </div>
      <div className={css(styles.bottomActions)}>
        <CreatorClaimedAction creator={creator} />
        <CardFollowAction
          entity={creator}
          entity_type="creator"
          allowFollow
          styles={followStyle}
        />
        <CreatorEllipsisMenu
          creator={creator}
          id="ellipsis"
          styles={ellipsisStyles}
          closeOnOutsideclick
        />
      </div>
    </LazyLoadComponent>
  );

  return (
    <div
      data-id="creator-desktop-card"
      className={css(
        styles.creatorDesktopCard,
        noPanel && styles.creatorDesktopCardNoPanel,
        styles.entityCard
      )}
    >
      {renderTopRow()}
      {renderMiddleRow()}
      {renderBottomRow()}
    </div>
  );
};

CreatorDesktopCard.propTypes = {
  creator: PropTypes.instanceOf(Map),
  noPanel: PropTypes.bool,
  buttons: PropTypes.node,
  actions: PropTypes.array,
  insidePanel: PropTypes.bool,
  hideSecondaryButtons: PropTypes.bool,
  renderEntityImageOverlay: PropTypes.func,
  hideInfoIcons: PropTypes.bool,
  renderOnMount: PropTypes.bool,
};

CreatorDesktopCard.defaultProps = {
  creator: null,
  noPanel: false,
  insidePanel: false,
  buttons: null,
  actions: null,
  hideSecondaryButtons: false,
  renderEntityImageOverlay: null,
  hideInfoIcons: false,
  renderOnMount: false,
};

export default CreatorDesktopCard;
